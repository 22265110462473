<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12">
        <card-organization :cardData="cardData" />
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-tabs background-color="primary" dark class="tabs">
            <v-tab>Cadastro</v-tab>
            <v-tab>Configuração</v-tab>
            <v-tab>Perfis de Acesso</v-tab>
            <v-tab-item class="py-4">
              <form-organization action="edit" :organization="organizationData"></form-organization>
            </v-tab-item>
            <v-tab-item class="py-4">
              <form-organization-config action="edit" :organization="organizationData"></form-organization-config>
            </v-tab-item>
            <v-tab-item class="py-8">
              <add-people :dialog="dialog" @close="dialog.show = false" @save="savingPeople()"></add-people>
              <base-card @addModal="openDialog" heading="Perfis de Acesso" :dialog="true" :manage="false" :button="button">
                <datatable-list-adm
                  :text="tableAdmins.text"
                  :headers="tableAdmins.headers"
                  :sortBy="tableAdmins.sortBy"
                  :items="tableAdmins.items"
                  v-if="fetchAdm"
                  :showSelect="tableAdmins.showSelect"
                  :displayActionItems="tableAdmins.displayActionItems"
                  :tableUser="true"
                  :pagination="tableAdmins.pagination"
                  :loading="!fetchAdm"
                  @update="tableAdmins.pagination = { ...tableAdmins.pagination, ...$event}"
                  @getPeople="getPeople"
                ></datatable-list-adm>
                <v-skeleton-loader
                  v-for="i in 5"
                  v-else
                  :key="i"
                  type="table-heading"
                ></v-skeleton-loader>
              </base-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: () => ({
    action: 'edit',
    cardData: {
      title: 'Secretaria da Educação de Curitiba',
      subtitle: '@sme.curitiba.pr.gov.br',
      image:
        'https://www.youbilingue.com.br/blog/wp-content/uploads/2019/06/capa-blog-diferenciar-escola-min.png',
      gradient: true
    },
    tableAdmins: {
      text: {
        searchLabel: 'Pesquisar administradores',
        emptyLabel: 'Nenhum administrador encontrado',
        addLabel: 'Adicionar administradores',
        addUrl: '/school/add',
        importLabel: 'Importar administradores',
        importUrl: '/school/add'
      },
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'name'
        },
        {
          text: 'Permissões',
          align: 'center',
          value: 'roles'
        },
        {
          text: '',
          align: 'center',
          value: 'options',
          sortable: false
        }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 15,
        prev_page_url: false,
        next_page_url: false
      },
      sortBy: '',
      showSelect: false,
      displayActionItems: false,
      items: []
    },
    fetchAdm: false,
    button: {
      show: true,
      text: 'Administradores',
      icon: 'mdi-plus'
    },
    dialog: {},
    organization: {
      registeredNumber: '76.417.005/0012-39',
      industry: 'Governo',
      name: 'Secretaria Municipal da Educação de Curitiba',
      email: 'educacao@sme.curitiba.gov.br',
      phone: '(41) 3330-3131',
      zipCode: '80030-000',
      streetName: 'Avenida João Gualberto',
      number: '623',
      complement: null,
      district: 'Alto da Glória',
      city: 'Curitiba',
      state: 'PR',
      domains: ['@sme.curitiba.pr.gov.br'],
      admins: [
        {
          name: 'Britta Holt'
        },
        {
          name: 'Jane Smith'
        }
      ],
      coordinators: [
        {
          name: 'John Smith'
        },
        {
          name: 'Sandra Williams'
        }
      ]
    }
  }),
  computed: {
    ...mapState({
      activeOrganization: state => state.organization.activeOrganization
    }),
    organizationData: {
      get () {
        return this.activeOrganization
      },
      set (val) {
        return val
      }
    }
  },
  mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')

    this.getPeople()
    this.dialog = {
      show: false,
      title: 'Perfis de Acesso',
      linkSave: `/organizations/${this.id}/people/sync`,
      role: 'ADMIN'
    }
  },
  methods: {
    ...mapActions('organization', ['getOrganizations', 'getOrganization']),
    update () {
      alert('ok')
    },
    openDialog () {
      this.dialog.show = true
    },
    buildQuery () {
      const pagination = `?roles[]=ADMIN&roles[]=TECHNICAL&roles[]=FINANCIAL&roles[]=ORG_PEDAGOGICAL_COORDINATOR&page=${this.tableAdmins.pagination.page}&per_page=${this.tableAdmins.pagination.itemsPerPage}`
      return pagination
    },
    async getPeople () {
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')
      if (id) {
        this.fetchAdm = false
        await this.$axios.get(`/organizations/${id}/people${this.buildQuery()}`)
          .then(response => {
            const items = response.data.items

            const arr = []
            if (items.length) {
              items.forEach((value, index) => {
                if (value.data) {
                  const obj = {
                    avatar: value.data.user.avatar,
                    email: value.data.user.email,
                    id: value.data.user.id,
                    name: value.data.user.name,
                    roles: value.data.roles,
                    metadata: value.metadata,
                    permissions: value.permissions
                  }
                  arr.push(obj)
                }
              })
            }
            this.tableAdmins.items = arr

            const pagination = response.data.pagination
            this.pagination = {
              ...this.pagination,
              total: pagination.total,
              page: pagination.current_page,
              itemsPerPage: pagination.per_page
            }
          })
        this.fetchAdm = true
      }
    },

    savingPeople () {
      this.dialog.show = false
      this.getPeople()
    }
  }
}
</script>
<style >
.v-item-group > .v-slide-group__next,
.v-item-group > .v-slide-group__prev {
  display: none !important;
  max-width: 0;
}
</style>
